import {
  getPointsMiddle,
  appendLine,
  createPointsLineDown,
  createPointsLineUp,
  appendTextBox,
} from 'visualizer/createTree';

import { processData } from 'utils/memberUtil';

let lines = [];
let textBoxes = [];

const positionElements = (
  dimensions,
  svg,
  members,
  defaultMember,
  resetPosition
) => {
  const data = processData(members, defaultMember);
  console.log({ members });

  const { width } = dimensions;
  const boxWidth = 200;
  const middleX = width / 2;
  const middleY = 300;
  const margin = 10;
  const spaceBetween = boxWidth * 1.5 + margin / 2;
  const UNKNOWN = 'Unknown';

  const props = { svg, dimensions, points: [], width: boxWidth };

  const getTextArray = ({ fullName, dateOfBirth }) => {
    return [fullName, dateOfBirth];
  };
  const mutateOffset = (array, depth, index, offset) => {
    let hasMutatedDepth = false;
    for (let i = index; i < array[depth].length; i++) {
      array[depth][i].offset += offset;
      const { childRef } = array[depth][i];
      if (!hasMutatedDepth && childRef) {
        const { start } = childRef;
        mutateOffset(array, depth + 1, start, offset);
        hasMutatedDepth = true;
      }
    }
  };

  const renderChildren = (children = [], originPoints) => {
    const childrenArray = [];
    const populateChildrenArray = (children, depth = 0) => {
      children.forEach(child => {
        if (!childrenArray[depth]) {
          childrenArray[depth] = [];
        }
        if ((child.children || []).length) {
          const { children } = child;
          const start = (childrenArray[depth + 1] || []).length;
          const { length } = children;
          const end = start + length;
          child.childRef = { start, end };
          populateChildrenArray(children, depth + 1);
        }
        childrenArray[depth].push(child);
      });
    };
    populateChildrenArray(children);

    for (let depth = childrenArray.length - 1; depth >= 0; depth--) {
      let lastOffset = 0;
      childrenArray[depth].forEach((child, i) => {
        let initialOffset = lastOffset;
        if ((child.children || []).length) {
          const { childRef, children } = child;
          const { start, end } = childRef;
          const childAdjustedPosition =
            childrenArray[depth + 1]
              .slice(start, end)
              .reduce((num, grandChild) => (num += grandChild.offset), 0) /
            children.length;
          if (!i && childAdjustedPosition !== initialOffset) {
            mutateOffset(
              childrenArray,
              depth + 1,
              start,
              -childAdjustedPosition
            );
          } else if (childAdjustedPosition < initialOffset) {
            mutateOffset(
              childrenArray,
              depth + 1,
              start,
              initialOffset - childAdjustedPosition
            );
          } else if (childAdjustedPosition > initialOffset) {
            initialOffset = childAdjustedPosition;
          }
        }
        child.offset = initialOffset;
        lastOffset = initialOffset + 2;
      });

      if (!depth) {
        const topLevelOffset =
          childrenArray[0].reduce((num, child) => (num += child.offset), 0) /
          children.length;
        mutateOffset(childrenArray, 0, 0, -topLevelOffset);
      }
    }

    // In refactor consider using d3's enter
    childrenArray.forEach((array, depth) => {
      array.forEach(child => {
        const padding = boxWidth + margin;
        const x = originPoints[0];
        const position = x + (padding * child.offset) / 2;
        const y = originPoints[1] + boxWidth * (depth + 1);
        child.points = [position, y];
        child.depth = depth + 1;
      });
    });

    const addToArrays = (children, originPoints) => {
      children.forEach(child => {
        const { memberId, points, depth } = child;
        lines.push({
          ...props,
          points: createPointsLineDown(originPoints, points),
          delay: depth,
        });
        textBoxes.push({
          ...props,
          points,
          memberId,
          textArray: getTextArray(child),
          delay: depth,
        });
        if ((child.children || []).length) {
          addToArrays(child.children, points);
        }
      });
    };

    addToArrays(children, originPoints);
  };

  const getParentsWithUnknown = parents => {
    if (parents.length === 1) {
      return [
        ...parents,
        {
          fullName: UNKNOWN,
          unknown: true,
        },
      ];
    }
    return parents;
  };

  const getNestedParents = (
    parents,
    originPoints,
    depth = 0,
    nestedParents = []
  ) => {
    const parentsWithUnknown = getParentsWithUnknown(parents);
    parentsWithUnknown.forEach(parent => {
      const points = [];
      const parentWithOriginPointer = {
        ...parent,
        points,
        originPoints,
      };
      if ((parent.parents || []).length) {
        const { parents } = parent;
        getNestedParents(parents, points, depth + 1, nestedParents);
      }
      if (!nestedParents[depth]) {
        nestedParents[depth] = [];
      }
      nestedParents[depth].push(parentWithOriginPointer);
    });
    return nestedParents;
  };
  const getPoint = (originPoints, index, len, middle) => {
    const middleIndex = len / 2;
    const positionOffset = index - middleIndex;
    const padding = boxWidth + margin;
    const positionX = middle + positionOffset * padding;
    const positionY = originPoints[1] - boxWidth;
    return [positionX, positionY];
  };
  const getParentWithCalculatedPoints = (parent, depth, index, len, middle) => {
    const { originPoints, points } = parent;
    points.push(...getPoint(originPoints, index, len, middle));
    return {
      ...props,
      ...parent,
      textArray: [parent.fullName],
      delay: depth + 1,
    };
  };

  const getActiveMiddle = parents => {
    if ((parents || []).length === 0) {
      return middleX;
    }
    const [combinedX, count] = parents.reduce(
      (totals, { points, parents }) => {
        const [combinedX, count] = totals;
        if ((parents || []).length > 0 && (points || []).length > 0) {
          return [combinedX + points[0], count + 1];
        }
        return totals;
      },
      [0, 0]
    );
    return combinedX / count + 100;
  };

  const getParentPositions = targetMember => {
    const { points: targetPoints, parents = [] } = targetMember;
    const nestedParents = getNestedParents(parents, targetPoints);
    console.log({ nestedParents });
    return nestedParents.reduce((parentPositions, parents, depth) => {
      const len = parents.length;
      const activeMiddle = getActiveMiddle(nestedParents[depth - 1]);
      const updatedParents = parents.map((parent, index) =>
        getParentWithCalculatedPoints(parent, depth, index, len, activeMiddle)
      );
      return [...parentPositions, ...updatedParents];
    }, []);
  };

  const renderParents = startingTargets => {
    const parentsArray = [];
    const mockUp = [];

    const populateParentsArray = (parents, depth = 0) => {
      if (!parentsArray[depth]) {
        parentsArray[depth] = [];
      }
      for (let i = 0; i < 2; i++) {
        if (!parents[i]) {
          parents[i] = {
            fullName: UNKNOWN,
            unknown: true,
          };
        }
        if ((parents[i].parents || []).length) {
          populateParentsArray(parents[i].parents, depth + 1);
        }
      }
      parentsArray[depth].unshift(parents);
    };

    startingTargets.forEach(origin => {
      const { parents = [] } = origin;
      if (parents.length) {
        populateParentsArray(parents);
      }
    });

    const getMockChild = (curIndex, depth) => {
      const binary = curIndex.toString(2);
      const whichPartner = +binary.slice(-1);
      const childIndex = parseInt(+binary.slice(0, -1), 2);
      const position = mockUp[depth - 1][childIndex];

      return position && position[whichPartner];
    };

    for (
      let depth = 0, rowLength = startingTargets.length;
      depth < parentsArray.length;
      depth++
    ) {
      const mockCopy = [...parentsArray[depth]];
      if (!mockUp[depth]) {
        mockUp[depth] = [];
      }
      for (let i = 0; i < rowLength; i++) {
        if (depth) {
          const mockChild = getMockChild(i, depth);
          if (mockChild && !mockChild.unknown) {
            mockUp[depth].push(mockCopy.pop());
          }
        } else {
          mockUp[depth].push(mockCopy.pop());
        }
      }
      rowLength *= 2;
    }

    const getPoint = (originPoints, position, depth) => {
      const depthOffset =
        parentsArray[depth].length * ((boxWidth + margin) / 2);
      const padding = boxWidth + margin;
      const positionX = originPoints[0] + padding * position - depthOffset;
      const positionY = originPoints[1] - boxWidth;
      return [positionX, positionY];
    };

    mockUp.forEach((depthArray, depth) => {
      depthArray.forEach((parents, i) => {
        if (parents) {
          parents.forEach((parent, j) => {
            let startPoints;
            if (depth) {
              const child = getMockChild(i, depth);
              parent.points = getPoint(child.points, i + j, depth);
              startPoints = child.points;
            } else {
              const { points } = startingTargets[i];
              const parentPoints = getPoint(points, i + j, depth);
              if (startingTargets.length > 1) {
                const pointsOffset = !i
                  ? parentPoints[0] + boxWidth / 2
                  : parentPoints[0] - boxWidth / 2;
                parent.points = [pointsOffset, parentPoints[1]];
              } else {
                parent.points = parentPoints;
              }
              startPoints = points;
            }
            const delay = depth + 1;
            const { memberId, points } = parent;
            lines.push({
              ...props,
              points: createPointsLineUp(startPoints, points),
              delay,
            });
            textBoxes.push({
              ...props,
              points,
              memberId,
              textArray: getTextArray(parent),
              delay,
            });
          });
        }
      });
    });
  };

  const createTarget = () => {
    // To do: allow for more than one partner || allow different combos.
    const target = { ...data[0] };
    if (data.length > 1) {
      const targetPartner = { ...data[1] };
      target.points = [middleX - spaceBetween, middleY];
      targetPartner.points = [
        middleX + spaceBetween - dimensions.paddingRight,
        middleY,
      ];
      const middle = getPointsMiddle(target.points, targetPartner.points);

      lines.push({
        ...props,
        points: [target.points, targetPartner.points],
      });
      textBoxes.push({
        ...props,
        points: target.points,
        textArray: getTextArray(target),
      });
      textBoxes.push({
        ...props,
        memberId: targetPartner.memberId,
        points: targetPartner.points,
        textArray: getTextArray(targetPartner),
      });

      renderChildren(target.children, middle);
      renderParents([targetPartner, target]);
    } else {
      const middle = [middleX - boxWidth / 2, middleY];

      const targetMember = {
        ...props,
        ...target,
        points: middle,
        textArray: getTextArray(target),
      };

      textBoxes.push(targetMember);

      renderChildren(target.children, middle);
      const parentPositions = getParentPositions(targetMember);
      const parentLines = parentPositions.map(
        ({ points, originPoints, delay }) => ({
          ...props,
          points: createPointsLineUp(originPoints, points),
          delay,
        })
      );
      textBoxes.push(...parentPositions);
      lines.push(...parentLines);
    }
  };

  createTarget();

  const onClickBox = textBox => () => {
    svg.selectAll('*').remove();
    // quickfix, arrays not clearing
    // reset positions as well
    lines = [];
    textBoxes = [];
    positionElements(dimensions, svg, members, textBox.memberId, resetPosition);
    resetPosition();
  };

  lines.forEach(line => appendLine(line));
  textBoxes.forEach(textBox => {
    let props = textBox;
    if (textBox.memberId) {
      props.onClick = onClickBox(textBox);
    }
    appendTextBox(props);
  });
};

export default positionElements;
