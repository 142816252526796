import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import BaseInput from 'components/formComponents/Inputs/BaseInput';
import Password from 'components/formComponents/Inputs/Password';
import Form, { FormContainer } from 'components/formComponents/Form/Form';
import Button from 'components/formComponents/Button/Button';
import Error from 'components/Error/Error';
import { login, resetErrors } from 'actions/authenticationActions';
import { getBaseUrl } from 'core';
import { handleChange } from 'utils/formUtil';
import { string, func, object } from 'prop-types';
import './LogIn.css';

const getErrorMessage = state => {
  const { from = {} } = state || {};
  const { pathname = '' } = from;
  if (pathname) {
    const baseUrl = getBaseUrl();
    // remove `/` from pathname, as included in baseUrl
    const url = baseUrl + pathname.slice(1);
    return `You can't navigate to "${url}" without first logging in.`;
  }
  return '';
};

export const LogIn = props => {
  const { login, resetErrors, location, error } = props;
  const { state } = location;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const resetPassword = () => setPassword('');
  useEffect(() => {
    return () => {
      resetErrors();
    };
  }, [resetErrors]);
  return (
    <div className="CenteredContainer">
      {/* In case of redirect explain why showing this page */}
      <Error>{getErrorMessage(state)}</Error>
      <Error>{error}</Error>
      <FormContainer>
        <h1>Log In</h1>
        <Form onSubmit={() => login({ email, password }, resetPassword)}>
          <BaseInput onChange={handleChange(setEmail)} value={email} required>
            Email
          </BaseInput>
          <Password onChange={handleChange(setPassword)} value={password} />
          <Button type="submit">Log In</Button>
        </Form>
      </FormContainer>
    </div>
  );
};

LogIn.propTypes = {
  error: string,
  login: func.isRequired,
  resetErrors: func.isRequired,
  location: object.isRequired,
};

const mapStateToProps = ({ authentication }) => ({
  error: authentication.error,
});

export default connect(
  mapStateToProps,
  { login, resetErrors }
)(LogIn);
