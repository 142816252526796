import React from 'react';
import './Button.css';
import { string } from 'prop-types';

const Button = ({ children, ...props }) => {
  return (
    <button {...props}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: string.isRequired,
};

export default Button;
