import { select, call, takeEvery, take, put } from 'redux-saga/effects';

import {
  createMember,
  getMemberChannel,
  editMembers,
  deleteMember,
} from 'firebaseApp';

import {
  CREATE_MEMBER,
  MEMBERS_CHANGE,
  EDIT_MEMBER,
  DELETE_MEMBER,
} from 'actions/memberActions.js';
import { AUTH_SUCCESS } from 'actions/authenticationActions';

import {
  getMemberFormattedForService,
  getAllMembersWithUpdatedIds,
} from 'utils/memberUtil';

const getUidFromState = ({ authentication }) => authentication.uid;
const getMembersFromState = ({ familyData }) => familyData.members;

function* createMemberAsync({ updatedMember }) {
  const uid = yield select(getUidFromState);
  const members = yield select(getMembersFromState);

  try {
    const { key } = yield call(createMember, uid, updatedMember);
    // After creating,update associated members
    const updatesList = getAllMembersWithUpdatedIds(
      { ...updatedMember, memberId: key },
      members
    );
    if (updatesList.length > 0) {
      yield call(editMembers, uid, updatesList);
    }
  } catch (e) {
    // TODO: add error message
    console.error('createMemberAsync::catch:', e);
  }
}

// createRequestUseUpdate

function* editMemberAsync({ updatedMember }) {
  const uid = yield select(getUidFromState);
  const members = yield select(getMembersFromState);

  const updatesList = [
    getMemberFormattedForService(updatedMember),
    ...getAllMembersWithUpdatedIds(updatedMember, members),
  ];
  try {
    yield call(editMembers, uid, updatesList);
  } catch (e) {
    // TODO: add error message
    console.error('editMemberAsync::catch:', e);
  }
}

function* deleteMemberAsync({ updatedMember }) {
  const uid = yield select(getUidFromState);
  const members = yield select(getMembersFromState);

  const updatesList = getAllMembersWithUpdatedIds(updatedMember, members);
  try {
    yield call(deleteMember, uid, updatedMember);
    if (updatesList.length > 0) {
      yield call(editMembers, uid, updatesList);
    }
  } catch (e) {
    // TODO: add error message
    console.error('editMemberAsync::catch:', e);
  }
}

export function* watchForFirebaseMemberAsync({ uid }) {
  const channel = yield call(getMemberChannel, uid);
  try {
    while (true) {
      const { members } = yield take(channel);
      yield put({ type: MEMBERS_CHANGE, members });
    }
  } catch (e) {
    // TODO: add edge case here
    console.error({ e });
  }
}

export function* createMemberSaga() {
  yield takeEvery(CREATE_MEMBER, createMemberAsync);
}
export function* editMemberSaga() {
  yield takeEvery(EDIT_MEMBER, editMemberAsync);
}
export function* deleteMemberSaga() {
  yield takeEvery(DELETE_MEMBER, deleteMemberAsync);
}
export function* watchForFirebaseMemberSaga() {
  yield takeEvery(AUTH_SUCCESS, watchForFirebaseMemberAsync);
}
