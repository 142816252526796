import { eventChannel } from 'redux-saga';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyDOgOVwq0xI5I87oe7uDqupNSlXUPQAYUk',
  authDomain: 'family-tree-database.firebaseapp.com',
  databaseURL: 'https://family-tree-database.firebaseio.com',
  projectId: 'family-tree-database',
  storageBucket: 'family-tree-database.appspot.com',
  messagingSenderId: '298809092675',
};

firebase.initializeApp(config);
const dbRef = firebase.database().ref();

// Auth
export function getAuthChannel() {
  const authChannel = eventChannel(emit => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(user => emit({ user }));
    return unsubscribe;
  });
  return authChannel;
}

export const handleSignInUser = (email, password) => {
  return firebase.auth().signInWithEmailAndPassword(email, password);
};

export const handleSignUp = (email, password) => {
  return firebase.auth().createUserWithEmailAndPassword(email, password);
};

export const handleSignOut = () => {
  return firebase.auth().signOut();
};

// Members
export const createMember = (uid, updatedMember) => {
  return dbRef.child(`users/${uid}/members`).push(updatedMember);
};

export const editMembers = (uid, updatesList) => {
  const updates = updatesList.reduce((updates, updateItem) => {
    const { memberId, property = '', postData } = updateItem;
    const updateKey = `/users/${uid}/members/${memberId}/${property}`;
    return { ...updates, [updateKey]: postData };
  }, {});
  return dbRef.update(updates);
};

export const deleteMember = (uid, updatedMember) => {
  const { memberId } = updatedMember;
  return dbRef.child(`/users/${uid}/members/${memberId}/`).remove();
};

export function getMemberChannel(uid) {
  const dbFamiliesRef = dbRef.child(`users/${uid}/members`);
  const channel = eventChannel(emit => {
    const unsubscribe = dbFamiliesRef.on('value', snap => {
      const members = snap.val();
      emit({ members });
    });
    return unsubscribe;
  });
  return channel;
}
// Options
export const selectDefaultMember = (uid, memberId) => {
  return dbRef.child(`users/${uid}/options`).set({
    defaultMember: memberId,
  });
};

export function getOptionsChannel(uid) {
  const dbFamiliesRef = dbRef.child(`users/${uid}/options`);
  const channel = eventChannel(emit => {
    const unsubscribe = dbFamiliesRef.on('value', snap => {
      const options = snap.val();
      emit({ options });
    });
    return unsubscribe;
  });
  return channel;
}
