import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './Error.css';
import { string } from 'prop-types';

const Error = ({ children }) => {
  const [showError, setShowError] = useState(true);
  useEffect(() => {
    setShowError(true);
  }, [children]);
  return (
    <CSSTransition
      in={showError && !!children}
      timeout={400}
      classNames="ErrorAnimation"
      unmountOnExit
    >
      <div className="Error">
        <span className="close" onClick={() => setShowError(false)}>
          {String.fromCharCode(10005)}
        </span>
        <span>{children}</span>
      </div>
    </CSSTransition>
  );
};

Error.propTypes = {
  children: string,
};

export default Error;
