import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router'
import { bool, func, object, oneOfType } from 'prop-types'

const PostLoginRoute = ({ component: Component, loggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/Log In',
              // TODO: propTypes
              // eslint-disable-next-line react/prop-types
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

PostLoginRoute.propTypes = {
  component: oneOfType([func, object]),
  loggedIn: bool,
}

const mapStateToProps = ({ authentication = {} }) => ({
  loggedIn: authentication.loggedIn,
})

export default connect(mapStateToProps)(PostLoginRoute)
