import {
  LOG_IN,
  AUTH_SUCCESS,
  AUTH_ERROR,
  LOG_OUT,
  RESET_ERRORS,
} from 'actions/authenticationActions';

const INITIAL_STATE = {
  loggedIn: false,
  loading: false,
  error: '',
  uid: '',
};

const authentication = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOG_IN:
      return {
        loggedIn: false,
        loading: true,
        error: '',
      };
    case AUTH_SUCCESS:
      return {
        loggedIn: true,
        loading: false,
        uid: action.uid,
        error: '',
      };
    case AUTH_ERROR:
      return {
        loggedIn: false,
        loading: false,
        error: action.error,
      };
    case RESET_ERRORS:
      return {
        ...state,
        error: '',
      };
    case LOG_OUT:
      return {
        ...INITIAL_STATE,
        loggedIn: false,
      };
    default:
      return state;
  }
};

export default authentication;
