import { fork } from 'redux-saga/effects';
import {
  loginSaga,
  signUpSaga,
  logoutSaga,
  watchForFirebaseAuth,
} from 'middleware/authenticationSagas';
import {
  createMemberSaga,
  editMemberSaga,
  deleteMemberSaga,
  watchForFirebaseMemberSaga,
} from 'middleware/memberSagas';
import {
  selectDefaultMemberSaga,
  watchForFirebaseOptionsSaga,
} from 'middleware/optionsSagas';

export default function* rootSaga() {
  yield fork(loginSaga);
  yield fork(signUpSaga);
  yield fork(logoutSaga);
  yield fork(watchForFirebaseAuth);
  yield fork(createMemberSaga);
  yield fork(editMemberSaga);
  yield fork(deleteMemberSaga);
  yield fork(watchForFirebaseMemberSaga);
  yield fork(selectDefaultMemberSaga);
  yield fork(watchForFirebaseOptionsSaga);
}
