import { MEMBERS_CHANGE } from 'actions/memberActions.js';

const INITIAL_STATE = {
  members: {},
};

const familyData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MEMBERS_CHANGE:
      return {
        members: action.members || INITIAL_STATE.members,
      };
    default:
      return state;
  }
};

export default familyData;
