import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';
import { bool, object } from 'prop-types';

const PreLoginRoute = ({ component: Component, loggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
    />
  );
};

PreLoginRoute.propTypes = {
  component: object,
  loggedIn: bool,
};

const mapStateToProps = ({ authentication = {} }) => ({
  loggedIn: authentication.loggedIn,
});

export default connect(mapStateToProps)(PreLoginRoute);
