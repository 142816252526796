import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import BaseInput from 'components/formComponents/Inputs/BaseInput';
import Password from 'components/formComponents/Inputs/Password';
import Form, { FormContainer } from 'components/formComponents/Form/Form';
import Button from 'components/formComponents/Button/Button';
import Error from 'components/Error/Error';
import { signUp, resetErrors } from 'actions/authenticationActions';
import { getBaseUrl } from 'core';
import { handleChange } from 'utils/formUtil';
import { string, func, object } from 'prop-types';
import './SignUp.css';

const getErrorMessage = state => {
  const { from = {} } = state || {};
  const { pathname = '' } = from;
  if (pathname) {
    const baseUrl = getBaseUrl();
    // remove `/` from pathname, as included in baseUrl
    const url = baseUrl + pathname.slice(1);
    return `You can't navigate to "${url}" without first logging in.`;
  }
  return '';
};

const onBlur = (password, retypedPassword, setMismatchError) => {
  if (areNotSameString(password, retypedPassword)) {
    setMismatchError('Passwords must match');
  }
};

const areSameString = (string1, string2) =>
  string1 && string2 && string1 === string2;

const areNotSameString = (string1, string2) =>
  string1 && string2 && string1 !== string2;

export const SignUp = props => {
  const { signUp, resetErrors, location, error } = props;
  const { state } = location;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [retypedPassword, setRetypedPassword] = useState('');
  const [mismatchError, setMismatchError] = useState('');
  useEffect(() => {
    return () => {
      resetErrors();
    };
  }, [resetErrors]);
  return (
    <div className="CenteredContainer">
      {/* In case of redirect explain why showing this page */}
      <Error>{getErrorMessage(state)}</Error>
      <Error>{error}</Error>
      <FormContainer>
        <h1>Sign Up</h1>
        <Form
          onSubmit={() => {
            if (areSameString(password, retypedPassword)) {
              signUp({ email, password });
            }
          }}
        >
          <BaseInput onChange={handleChange(setEmail)} value={email} required>
            Email
          </BaseInput>
          <Password
            onChange={handleChange(setPassword)}
            onFocus={() => setMismatchError('')}
            value={password}
            onBlur={() => onBlur(password, retypedPassword, setMismatchError)}
          />
          <Password
            onChange={handleChange(setRetypedPassword)}
            onFocus={() => setMismatchError('')}
            value={retypedPassword}
            error={mismatchError}
            onBlur={() => onBlur(password, retypedPassword, setMismatchError)}
          >
            Retype Password
          </Password>
          <Button type="submit">Sign Up!</Button>
        </Form>
      </FormContainer>
    </div>
  );
};

SignUp.propTypes = {
  error: string,
  signUp: func.isRequired,
  resetErrors: func.isRequired,
  location: object.isRequired,
};

const mapStateToProps = ({ authentication }) => ({
  error: authentication.error,
});

export default connect(
  mapStateToProps,
  { signUp, resetErrors }
)(SignUp);
