import React, { useState } from 'react';
import { connect } from 'react-redux';
import BaseInput from 'components/formComponents/Inputs/BaseInput';
import AutoComplete from 'components/formComponents/Inputs/AutoComplete';
import Button from 'components/formComponents/Button/Button';
import Form from 'components/formComponents/Form/Form';
import { createMember, editMember, deleteMember } from 'actions/memberActions';
import {
  getFormattedMembers,
  getMembersTextList,
  getFamilyNamesList,
  getMemberFromList,
} from 'utils/memberUtil';
import { handleChange } from 'utils/formUtil';
import { func, object, array, string, number } from 'prop-types';
import './MemberForm.css';
import Modal from 'components/Modal/Modal';

const MemberInput = ({
  membersList,
  list,
  excludeList,
  setList,
  children,
  max = Infinity,
}) => {
  const [searchText, setSearchText] = useState('');
  const includedMembers = membersList.filter(({ memberId }) =>
    list.includes(memberId)
  );
  const dropDownMembers = membersList.filter(
    ({ memberId }) => ![...excludeList, ...list].includes(memberId)
  );
  const moreMembersAllowed = includedMembers.length < max;
  const items = moreMembersAllowed ? dropDownMembers : [];
  return (
    <>
      <AutoComplete
        items={items}
        onSelectValue={({ memberId }) => {
          if (!list.includes(memberId)) {
            setList([...list, memberId]);
          }
        }}
        value={searchText}
        setValue={setSearchText}
        clearOnSelect
        disabled={items.length === 0}
      >
        {children}
      </AutoComplete>
      <div className="relatedMembersContainer">
        {includedMembers.map(member => {
          const { value, memberId } = member;
          return (
            <div key={memberId} className="memberContainer">
              <span className="relatedMember">{value}</span>
              <span
                className="removeMember"
                onClick={() =>
                  setList(list.filter(itemKey => itemKey !== memberId))
                }
              >
                Remove
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};

const getTitleString = isCreateMemberFlow => {
  return isCreateMemberFlow ? 'Add Member' : 'Confirm Edit';
};

const MemberForm = ({
  members,
  hideMemberForm,
  createMember,
  editMember,
  deleteMember,
  memberId,
}) => {
  const formatedMembers = getFormattedMembers(members);
  const familyNames = getFamilyNamesList(formatedMembers);
  const membersList = getMembersTextList(formatedMembers);
  const selectedMember = getMemberFromList(formatedMembers, memberId);
  // Pre-fill form with available data
  const [firstName, setFirstName] = useState(selectedMember.firstName || '');
  const [middleName, setMiddleName] = useState(selectedMember.middleName || '');
  const [lastName, setLastName] = useState(selectedMember.lastName || '');
  const [dateOfBirth, setDateOfBirth] = useState(
    selectedMember.dateOfBirth || ''
  );
  const [parents, setParents] = useState(selectedMember.parents || []);
  const [children, setChildren] = useState(selectedMember.children || []);
  const updatedMember = {
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    parents,
    children,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isCreateMemberFlow = !memberId || formatedMembers.length === 0;
  const titleString = getTitleString(isCreateMemberFlow);
  return (
    <div className="CenteredContainer">
      <Modal isOpen={isModalOpen} title="Action Can't be Undone">
        {membersList.length !== 0 && (
          <Button
            className="memberButton cancel"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </Button>
        )}
        {!isCreateMemberFlow && (
          <Button
            className="memberButton delete"
            onClick={() => {
              hideMemberForm();
              deleteMember({ memberId });
              setIsModalOpen(false);
            }}
          >
            Delete
          </Button>
        )}
      </Modal>
      <h1>{titleString}</h1>
      <Form
        onSubmit={() => {
          isCreateMemberFlow
            ? createMember(updatedMember)
            : editMember({ ...updatedMember, memberId });
          hideMemberForm();
        }}
      >
        <BaseInput
          onChange={handleChange(setFirstName)}
          value={firstName}
          required
        >
          First Name
        </BaseInput>
        <BaseInput onChange={handleChange(setMiddleName)} value={middleName}>
          Middle Name
        </BaseInput>
        <AutoComplete
          onChange={handleChange(setLastName)}
          items={familyNames}
          setValue={setLastName}
          value={lastName}
          required
        >
          Last Name
        </AutoComplete>
        {/* TODO: add estimated DOB checkbox */}
        <BaseInput
          onChange={handleChange(setDateOfBirth)}
          type="date"
          value={dateOfBirth}
        >
          Date of birth
        </BaseInput>
        <MemberInput
          membersList={membersList}
          list={parents}
          excludeList={[...children, memberId]}
          setList={setParents}
          max={2}
        >
          Parents
        </MemberInput>
        <MemberInput
          membersList={membersList}
          list={children}
          excludeList={[...parents, memberId]}
          setList={setChildren}
        >
          Children
        </MemberInput>
        <div className="buttonContainer">
          {membersList.length !== 0 && (
            <Button className="memberButton cancel" onClick={hideMemberForm}>
              Cancel
            </Button>
          )}
          {!isCreateMemberFlow && (
            <Button
              className="memberButton delete"
              onClick={e => {
                e.preventDefault();
                setIsModalOpen(true);
              }}
            >
              Delete
            </Button>
          )}
          <Button type="submit" className="memberButton">
            {titleString}
          </Button>
        </div>
      </Form>
    </div>
  );
};

MemberInput.propTypes = {
  membersList: array,
  list: array,
  excludeList: array,
  setList: func,
  children: string,
  max: number,
};

MemberForm.propTypes = {
  members: object.isRequired,
  hideMemberForm: func.isRequired,
  createMember: func.isRequired,
  editMember: func.isRequired,
  deleteMember: func.isRequired,
  // Pass memberId for Edit flow
  memberId: string.isRequired,
};

const mapStateToProps = ({ familyData }) => ({
  members: familyData.members,
});

export default connect(
  mapStateToProps,
  { createMember, editMember, deleteMember }
)(MemberForm);
