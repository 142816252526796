export const LOG_IN = 'LOG_IN';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';

export const SIGN_UP = 'SIGN_UP';

export const LOG_OUT = 'LOG_OUT';
export const RESET_ERRORS = 'RESET_ERRORS';

export const login = (credentials, resetPassword) => {
  return {
    type: LOG_IN,
    credentials,
    resetPassword,
  };
};
export const signUp = credentials => {
  return {
    type: SIGN_UP,
    credentials,
  };
};
export const logout = () => ({
  type: LOG_OUT,
});
export const resetErrors = () => ({
  type: RESET_ERRORS,
});
