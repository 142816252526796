import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  getFormattedMembers,
  getMembersTextList,
  getMemberFromList,
} from 'utils/memberUtil';
import AutoComplete from 'components/formComponents/Inputs/AutoComplete';
import { selectDefaultMember } from 'actions/optionsActions';
import { object, func, string } from 'prop-types';
import './Options.css';

const DefaultRelative = ({ currentDefaultMember, toggleShowSelectMember }) => {
  // TODO: Add link to edit tree if no members
  return (
    <>
      {!currentDefaultMember ? (
        <button onClick={toggleShowSelectMember}>
          Select Default Relative
        </button>
      ) : (
        <div>
          <span className="defaultMemberLabel">Default Member: </span>
          <span className="defaultMember">{currentDefaultMember}</span>
          <button className="update-button" onClick={toggleShowSelectMember}>
            Update
          </button>
        </div>
      )}
    </>
  );
};

const Options = ({ members, options, selectDefaultMember }) => {
  const { defaultMember } = options;
  const [showSelectMember, setShowSelectMember] = useState(false);
  const [searchText, setSearchText] = useState('');
  const formatedMembers = getFormattedMembers(members);
  const membersList = getMembersTextList(formatedMembers);
  const currentDefaultMember = getMemberFromList(membersList, defaultMember);
  const toggleShowSelectMember = () => setShowSelectMember(!showSelectMember);
  return (
    <div className="CenteredContainer">
      <h1>Tree Options</h1>
      {!showSelectMember ? (
        <DefaultRelative
          currentDefaultMember={currentDefaultMember.value}
          toggleShowSelectMember={toggleShowSelectMember}
        />
      ) : (
        <div>
          <AutoComplete
            items={membersList}
            onSelectValue={({ memberId }) => {
              toggleShowSelectMember();
              selectDefaultMember(memberId);
            }}
            value={searchText}
            setValue={setSearchText}
            clearOnSelect
          >
            Select default member
          </AutoComplete>
        </div>
      )}
    </div>
  );
};

DefaultRelative.propTypes = {
  currentDefaultMember: string,
  toggleShowSelectMember: func.isRequired,
};

Options.propTypes = {
  members: object.isRequired,
  options: object.isRequired,
  selectDefaultMember: func.isRequired,
};

const mapStateToProps = ({ familyData, options }) => ({
  members: familyData.members,
  options,
});

export default connect(
  mapStateToProps,
  { selectDefaultMember }
)(Options);
