import { put, call, takeEvery, take } from 'redux-saga/effects';
import {
  LOG_IN,
  SIGN_UP,
  AUTH_SUCCESS,
  AUTH_ERROR,
  LOG_OUT,
} from 'actions/authenticationActions';
import {
  getAuthChannel,
  handleSignInUser,
  handleSignUp,
  handleSignOut,
} from 'firebaseApp';

export function* watchForFirebaseAuth() {
  const channel = yield call(getAuthChannel);
  try {
    while (true) {
      const { user } = yield take(channel);
      if (user) {
        yield put({ type: AUTH_SUCCESS, uid: user.uid });
      } else {
        yield put({ type: LOG_OUT });
      }
    }
  } catch (e) {
    // TODO: add edge case here
    console.error({ e });
    yield put({ type: LOG_OUT });
  }
}

function* loginAsync({ credentials, resetPassword }) {
  const { email, password } = credentials;
  try {
    yield call(handleSignInUser, email, password);
  } catch (e) {
    yield put({ type: AUTH_ERROR, error: e.message });
    resetPassword();
  }
}

function* signUpAsync({ credentials, resetPassword }) {
  const { email, password } = credentials;
  try {
    yield call(handleSignUp, email, password);
  } catch (e) {
    yield put({ type: AUTH_ERROR, error: e.message });
    resetPassword();
  }
}

export function* logoutAsync() {
  try {
    yield call(handleSignOut);
  } catch (e) {
    // TODO: add error message
    console.error('logoutAsync::catch:', e);
  }
}

export function* loginSaga() {
  yield takeEvery(LOG_IN, loginAsync);
}
export function* signUpSaga() {
  yield takeEvery(SIGN_UP, signUpAsync);
}
export function* logoutSaga() {
  yield takeEvery(LOG_OUT, logoutAsync);
}
