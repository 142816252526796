import React from 'react';
import { CSSTransition } from 'react-transition-group';
import 'components/formComponents/Inputs/BaseInput.css';
import { string, element, object } from 'prop-types';

const BaseInput = ({ children, error, icon, childRef, ...props }) => {
  const name = children.replace(/\s/g, '_').toLowerCase();
  // TODO: Quickfix cuz not internet to lookup classNames
  const classNames = error ? 'BaseInput InputError' : 'BaseInput';
  return (
    <>
      <div className={classNames}>
        <label htmlFor={name}>{children}: </label>
        {icon}
        <input
          type="text"
          id={name}
          name={name}
          maxLength="100"
          size="10"
          ref={childRef}
          {...props}
        />
      </div>
      <div className="BaseAnimationContainer">
        <CSSTransition
          in={!!error}
          timeout={200}
          classNames="BaseErrorAnimation"
          unmountOnExit
        >
          <span className="ErrorMessage">{error}</span>
        </CSSTransition>
      </div>
    </>
  );
};

BaseInput.propTypes = {
  children: string.isRequired,
  icon: element,
  error: string,
  childRef: object,
};

export default BaseInput;
