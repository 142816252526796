import React from 'react';
import './Home.css';

const Home = () => {
  return (
    <div className="home">
      <h1>My Ancestry Tree</h1>
      <p>
        Create your own family tree! Family members are linked up by
        children/parent relationships. In the "View Tree" tab you can see how
        everyone connects, and by clicking on a family member see the tree from
        a new perspective. This is very much a work in progress, but if you want
        to see the progression please try it out!
      </p>
      <p>Hope you enjoy!</p>
      <p>~ Brian Keegan</p>
    </div>
  );
};

export default Home;
