import { select, call, takeEvery, take, put } from 'redux-saga/effects';

import { selectDefaultMember, getOptionsChannel } from 'firebaseApp';

import {
  SELECT_DEFAULT_MEMBER,
  OPTIONS_CHANGE,
} from 'actions/optionsActions.js';
import { AUTH_SUCCESS } from 'actions/authenticationActions';

function* selectDefaultMemberAsync({ memberId }) {
  try {
    const uid = yield select(({ authentication }) => authentication.uid);
    yield call(selectDefaultMember, uid, memberId);
  } catch (e) {
    // TODO: add error message
    console.error('createMemberAsync::catch:', e);
  }
}

export function* watchForFirebaseOptionsAsync({ uid }) {
  const channel = yield call(getOptionsChannel, uid);
  try {
    while (true) {
      const { options } = yield take(channel);
      yield put({ type: OPTIONS_CHANGE, options });
    }
  } catch (e) {
    // TODO: add edge case here
    console.error({ e });
  }
}

export function* selectDefaultMemberSaga() {
  yield takeEvery(SELECT_DEFAULT_MEMBER, selectDefaultMemberAsync);
}
export function* watchForFirebaseOptionsSaga() {
  yield takeEvery(AUTH_SUCCESS, watchForFirebaseOptionsAsync);
}
