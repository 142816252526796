import React, { useState, useRef } from 'react';
import BaseInput from 'components/formComponents/Inputs/BaseInput';
import { getFilteredItems } from 'utils/searchUtil';
import 'components/formComponents/Inputs/AutoComplete.css';
import { string, number, func, array, bool } from 'prop-types';

const UP_ARROW_CODE = 38;
const DOWN_ARROW_CODE = 40;
const ENTER_CODE = 13;
const TAB_CODE = 9;

const handleKeyDownCallback = (
  activeIndex,
  setActiveIndex,
  items,
  onSelectValue
) => e => {
  const len = items.length;
  if (e.keyCode === UP_ARROW_CODE) {
    e.preventDefault();
    const index = activeIndex - 1;
    setActiveIndex(index > -1 ? index : len - 1);
  } else if (e.keyCode === DOWN_ARROW_CODE) {
    e.preventDefault();
    const index = activeIndex + 1;
    setActiveIndex(index < len ? index : 0);
  } else if (
    (e.keyCode === ENTER_CODE || e.keyCode === TAB_CODE) &&
    activeIndex > -1
  ) {
    e.preventDefault();
    onSelectValue(items[activeIndex]);
    setActiveIndex(-1);
  }
};

const handleChange = (setValue, onChange, setShowSearch) => e => {
  setShowSearch(true);
  setValue(e.target.value);
  onChange(e);
};

const BoldMatch = ({ item, text }) => {
  const lowerCaseItem = item.toLowerCase();
  const lowerCaseText = text.toLowerCase();

  // @TODO:  Edge-case Match globally
  const startIndex = lowerCaseItem.indexOf(lowerCaseText);
  const endIndex = startIndex + text.length;

  const startText = item.slice(0, startIndex);
  const matchingText = item.slice(startIndex, endIndex);
  const endText = item.slice(endIndex);
  return (
    <>
      {startText}
      <span className="matchingString">{matchingText}</span>
      {endText}
    </>
  );
};

const DropDownItems = ({
  items,
  activeIndex,
  setActiveIndex,
  text,
  onClick,
  onSelectValue,
  ...props
}) => {
  return (
    <div className="matchContainer">
      {items.map((item, i) => {
        const { value, memberId } = item;
        const classNames = i === activeIndex ? 'match activeMatch' : 'match';
        return (
          <div
            key={memberId}
            className={classNames}
            onMouseOver={() => setActiveIndex(i)}
            {...props}
            onClick={() => {
              onClick();
              onSelectValue(item);
            }}
          >
            <BoldMatch item={value} text={text} />
          </div>
        );
      })}
    </div>
  );
};

const AutoComplete = ({
  children,
  items,
  value,
  setValue,
  onChange = () => {},
  onSelectValue = () => {},
  clearOnSelect = false,
  ...props
}) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [showSearch, setShowSearch] = useState(false);
  const elInputRef = useRef(null);
  const _onBlur = () => {
    setTimeout(() => setShowSearch(false), 200);
    setActiveIndex(-1);
  };
  const focusOnInput = () => {
    if (elInputRef.current) {
      elInputRef.current.focus();
    }
  };
  const _onSelectText = item => {
    const { value } = item;
    setValue(clearOnSelect ? '' : value);
    onSelectValue(item);
  };
  const filteredItems = getFilteredItems(items, value);
  return (
    <>
      <BaseInput
        onKeyDown={handleKeyDownCallback(
          activeIndex,
          setActiveIndex,
          filteredItems,
          _onSelectText
        )}
        autoComplete="off"
        onFocus={() => setShowSearch(true)}
        onBlur={_onBlur}
        value={value}
        onChange={handleChange(setValue, onChange, setShowSearch)}
        childRef={elInputRef}
        {...props}
      >
        {children}
      </BaseInput>
      <div className="AutoCompleteContainer">
        {showSearch && (
          <DropDownItems
            items={filteredItems}
            activeIndex={activeIndex}
            text={value}
            setActiveIndex={setActiveIndex}
            onClick={focusOnInput}
            onSelectValue={_onSelectText}
          />
        )}
      </div>
    </>
  );
};

// Types

BoldMatch.propTypes = {
  item: string.isRequired,
  text: string.isRequired,
};

DropDownItems.propTypes = {
  items: array.isRequired,
  activeIndex: number.isRequired,
  setActiveIndex: func.isRequired,
  text: string.isRequired,
  onClick: func.isRequired,
  onSelectValue: func.isRequired,
};

AutoComplete.propTypes = {
  children: string.isRequired,
  items: array.isRequired,
  setValue: func,
  value: string,
  onChange: func,
  onSelectValue: func,
  clearOnSelect: bool,
};

export default AutoComplete;
