import React from 'react';
import './Modal.css';
import { string, node, bool } from 'prop-types';

const Modal = ({ title, children, isOpen }) => {
  return (
    <div
      className={
        isOpen ? 'modal-backdrop modal-backdrop-open' : 'modal-backdrop'
      }
    >
      <div className="modal">
        <h1 className="title">{title}</h1>
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: string.isRequired,
  children: node.isRequired,
  isOpen: bool.isRequired,
};

export default Modal;
