import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logout } from 'actions/authenticationActions';
import { getFormattedMembers } from 'utils/memberUtil';
import 'containers/Header/Header.css';
import { bool, string, func, object } from 'prop-types';
import { GiHamburgerMenu } from 'react-icons/gi';
import { GrClose } from 'react-icons/gr';

const Link = ({ children, to, ...rest }) => {
  return (
    <NavLink
      to={to || `/${children}`}
      activeStyle={{
        fontWeight: '600',
        color: '#1b212b',
      }}
      {...rest}
    >
      {children}
    </NavLink>
  );
};

Link.propTypes = {
  children: string.isRequired,
  to: string,
};

const PreLoginHeader = () => {
  return (
    <header className="Header">
      <Link exact to="/">
        MyAncestryTree
      </Link>
      <Link className="pushRight">Sign Up</Link>
      <Link>Log In</Link>
    </header>
  );
};

const PostLoginHeader = ({ logout, hasMembers }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <header className="Header">
      <Link exact to="/" onClick={() => setIsOpen(false)}>
        MyAncestryTree
      </Link>
      <div className={isOpen ? 'dropdown-open' : 'dropdown'}>
        <Link onClick={() => setIsOpen(false)}>Edit Tree</Link>
        {hasMembers && (
          <>
            <Link onClick={() => setIsOpen(false)}>View Tree</Link>
            <Link onClick={() => setIsOpen(false)}>Options</Link>
          </>
        )}
        <NavLink
          className="pushRight"
          onClick={() => {
            logout();
            setIsOpen(false);
          }}
          to="/"
        >
          Logout
        </NavLink>
      </div>
      {isOpen && (
        <GrClose
          role="button"
          className="hamburger"
          onClick={() => setIsOpen(false)}
        />
      )}
      {!isOpen && (
        <GiHamburgerMenu
          role="button"
          className="hamburger"
          onClick={() => setIsOpen(true)}
        />
      )}
    </header>
  );
};

PostLoginHeader.propTypes = {
  logout: func,
  hasMembers: bool,
};

const Header = ({ loggedIn, logout, members }) => {
  const formatedMembers = getFormattedMembers(members);
  const hasMembers = formatedMembers.length > 0;
  return loggedIn ? (
    <PostLoginHeader logout={logout} hasMembers={hasMembers} />
  ) : (
    <PreLoginHeader />
  );
};

Header.propTypes = {
  members: object.isRequired,
  loggedIn: bool,
  logout: func,
};

const mapStateToProps = ({ authentication = {}, familyData }) => ({
  loggedIn: authentication.loggedIn,
  members: familyData.members,
});

export default connect(
  mapStateToProps,
  { logout }
)(Header);
