import { OPTIONS_CHANGE } from 'actions/optionsActions.js';

const INITIAL_STATE = {
  defaultMember: {},
};

const optionsData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPTIONS_CHANGE:
      return {
        defaultMember:
          (action.options || {}).defaultMember || INITIAL_STATE.defaultMember,
      };
    default:
      return state;
  }
};

export default optionsData;
