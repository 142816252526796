import * as d3 from 'd3';
import positionElements from 'visualizer/positionElements';

import 'visualizer/styles/main.css';

const width = window.innerWidth;
const height = window.innerHeight;

const dimensions = {
  width,
  height,
  paddingTop: 50,
  paddingLeft: 90,
  paddingRight: 90 * 2,
};
const zoomHandler = function() {
  const { x, y, k } = d3.event.transform;
  d3.select(this)
    .select('g')
    .attr('transform', `translate(${x},${y}) scale(${k})`);
};
const zoomListener = d3
  .zoom()
  .scaleExtent([0.2, 2])
  .on('zoom', zoomHandler);

export default (ref, members, defaultMember) => {
  const johnMisty = d3.select(ref).call(zoomListener);

  const svg = johnMisty
    .append('g')
    .attr('transform', 'translate(0, 0) scale(1)');

  const resetPosition = () => {
    johnMisty.call(zoomListener.transform, d3.zoomIdentity);
  };
  const breakdown = () => {
    svg.remove();
    resetPosition();
  };
  positionElements(dimensions, svg, members, defaultMember, resetPosition);
  return { resetPosition, breakdown };
};
