import React from 'react';
import './Form.css';
import { array, func } from 'prop-types';

export const FormContainer = ({ children }) => (
  <div className="FormContainer">{children}</div>
);

FormContainer.propTypes = {
  children: array.isRequired,
};

const Form = ({ children, onSubmit }) => {
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div className="Form">{children}</div>
    </form>
  );
};

Form.propTypes = {
  children: array.isRequired,
  onSubmit: func,
};

export default Form;
