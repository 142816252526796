import React from 'react';
import { Switch, Route } from 'react-router';
import 'containers/Main/Main.css';
import SignUp from 'containers/SignUp/SignUp.js';
import LogIn from 'containers/LogIn/LogIn.js';
import EditTree from 'containers/EditTree/EditTree.js';
import ViewTree from 'containers/ViewTree/ViewTree.js';
import Options from 'containers/Options/Options.js';
import PostLoginRoute from 'components/navigationComponents/PostLoginRoute';
import PreLoginRoute from 'components/navigationComponents/PreLoginRoute';
import NotFound from 'components/navigationComponents/NotFound';
import Home from 'containers/Home/Home.js';

const Main = () => {
  return (
    <main>
      <Switch>
        <Route exact path="/" component={Home} />
        <PreLoginRoute exact path="/Sign Up" component={SignUp} />
        <PreLoginRoute exact path="/Log In" component={LogIn} />
        <PostLoginRoute exact path="/Edit Tree" component={EditTree} />
        <PostLoginRoute exact path="/View Tree" component={ViewTree} />
        <PostLoginRoute exact path="/Options" component={Options} />
        <Route path="*" component={NotFound} />
      </Switch>
    </main>
  );
};

export default Main;
