import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import createVisualizer from 'visualizer';
import { object } from 'prop-types';
import './ViewTree.css';
import { Link } from 'react-router-dom';

const ViewTree = ({ members, options }) => {
  const { defaultMember } = options;
  const svgRef = useRef();
  useEffect(() => {
    if (svgRef.current) {
      const visualizer = createVisualizer(
        svgRef.current,
        members,
        defaultMember
      );
      return visualizer.breakdown;
    }
  });
  if (Object.keys(defaultMember).length === 0) {
    return (
      <p>
        Please select a default member in <Link to="Options">"Options"</Link> to
        view tree
      </p>
    );
  }
  return <svg id="john-misty" width={1200} height={700} ref={svgRef} />;
};

ViewTree.propTypes = {
  members: object.isRequired,
  options: object.isRequired,
};

const mapStateToProps = ({ familyData, options }) => ({
  members: familyData.members,
  options,
});

export default connect(mapStateToProps)(ViewTree);
