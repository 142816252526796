import React, { useState } from 'react';
import BaseInput from 'components/formComponents/Inputs/BaseInput';
import eyeOff from './eye-off.png';
import eyeOn from './eye.png';
import { string, bool, func } from 'prop-types';

const EyeOn = ({ eyeIsOn, onClick }) => {
  if (eyeIsOn) {
    return <img src={eyeOn} alt="Password Hidden" onClick={onClick} />;
  }
  return <img src={eyeOff} alt="Password Visible" onClick={onClick} />;
};

EyeOn.propTypes = {
  eyeIsOn: bool.isRequired,
  onClick: func.isRequired,
};

const Password = ({ children, error, ...props }) => {
  const [eyeIsOn, setEyeIsOn] = useState(true);
  const toggleEyeIsOn = () => setEyeIsOn(!eyeIsOn);
  const type = eyeIsOn ? 'password' : 'text';
  return (
    <>
      <BaseInput
        icon={<EyeOn eyeIsOn={eyeIsOn} onClick={toggleEyeIsOn} />}
        autoComplete="off"
        type={type}
        error={error}
        {...props}
      >
        {children || 'Password'}
      </BaseInput>
    </>
  );
};

Password.propTypes = {
  children: string,
  error: string,
};

export default Password;
