import { cloneDeep as _cloneDeep } from 'lodash';

const CHILDREN = 'children';
const PARENTS = 'parents';

const getFormattedMember = (members, memberId) => ({
  ...members[memberId],
  memberId,
});

export const getFormattedMembers = members =>
  Object.keys(members).map(memberId => getFormattedMember(members, memberId));

const divider = ', ';
export const getMembersTextList = formatedMembers =>
  formatedMembers.map(
    ({ firstName, middleName, lastName, dateOfBirth, memberId }) => {
      const value = [lastName, firstName, middleName, dateOfBirth]
        .filter(value => value)
        .join(divider);
      return { value, memberId };
    }
  );

export const getMemberFromList = (membersList, selectedUserId) => {
  return membersList.find(({ memberId }) => memberId === selectedUserId) || {};
};

export const getFamilyNamesList = formatedMembers => {
  return formatedMembers.reduce((familyNames, { lastName, memberId }) => {
    if (!familyNames.some(({ value }) => value === lastName)) {
      familyNames.push({ value: lastName, memberId });
    }
    return familyNames;
  }, []);
};

// Process data for d3
export const createMemberObject = (familyMembers, memberId) => {
  const member = familyMembers[memberId] || {};
  const { firstName, middleName, lastName } = member;
  const fullName = [firstName, middleName, lastName].join(' ');
  return { ...member, fullName, memberId };
};

export const getPropRecursively = (member, familyMembers, type) => {
  const listOfIds = member[type];
  return (listOfIds || []).map(currentId => {
    const member = createMemberObject(familyMembers, currentId);
    member[type] = getPropRecursively(member, familyMembers, type);
    return member;
  });
};

export const processData = (members, defaultMemberId) => {
  const familyMembers = _cloneDeep(members);
  const member = createMemberObject(familyMembers, defaultMemberId);
  // Parent and children stored as list of ids
  member.children = getPropRecursively(member, familyMembers, 'children');
  member.parents = getPropRecursively(member, familyMembers, 'parents');
  // In array because in future will add spouse
  return [member];
};

// For Sagas
export const getMemberFormattedForService = member => {
  const { memberId, ...postData } = member;
  return {
    memberId,
    postData,
  };
};

export const getUserIdsFormattedForService = (memberId, postData, property) => {
  return {
    memberId,
    postData,
    property,
  };
};

export const getIdsToUpdate = (updatedList, originalList) => {
  const idsToAdd = (updatedList || []).filter(
    memberId => !(originalList || []).includes(memberId)
  );
  const idsToRemove = (originalList || []).filter(
    memberId => !(updatedList || []).includes(memberId)
  );
  return [idsToAdd, idsToRemove];
};

export const getMembersWithIdAdded = (idsToAdd, members, idToAdd, property) => {
  return idsToAdd.map(memberId => {
    const member = getFormattedMember(members, memberId);
    // Add memberId to property
    const listOfIds = member[property] || [];
    const items = [...listOfIds, idToAdd];
    return { ...member, [property]: items };
  });
};

export const getMembersWithIdRemoved = (
  idsToRemove,
  members,
  idToRemove,
  property
) => {
  return idsToRemove.map(memberId => {
    const member = getFormattedMember(members, memberId);
    // Remove memberId from property
    const listOfIds = member[property] || [];
    const items = listOfIds.filter(memberId => memberId !== idToRemove);
    return { ...member, [property]: items };
  });
};

// Generic for reuse
export const getMembersWithUpdatedIds = (
  updatedMember,
  members,
  propertyToGetIds,
  propertyToUpdate
) => {
  const { memberId } = updatedMember;
  // Get originalMember (before changes)
  // Default to {} because when creating new, original doesn't exist
  const originalMember = members[memberId] || {};
  const [idsToAdd, idsToRemove] = getIdsToUpdate(
    updatedMember[propertyToGetIds],
    originalMember[propertyToGetIds]
  );
  const membersWithUpdatedIds = [
    ...getMembersWithIdRemoved(
      idsToRemove,
      members,
      memberId,
      propertyToUpdate
    ),
    ...getMembersWithIdAdded(idsToAdd, members, memberId, propertyToUpdate),
  ];
  return membersWithUpdatedIds.map(member =>
    getUserIdsFormattedForService(
      member.memberId,
      member[propertyToUpdate],
      propertyToUpdate
    )
  );
};

// Used in memberSaga
export const getAllMembersWithUpdatedIds = (updatedMember, members) => {
  return [
    ...getMembersWithUpdatedIds(updatedMember, members, PARENTS, CHILDREN),
    ...getMembersWithUpdatedIds(updatedMember, members, CHILDREN, PARENTS),
  ];
};
