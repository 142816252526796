import React, { useState } from 'react';
import { connect } from 'react-redux';
import MemberForm from 'containers/EditTree/MemberForm/MemberForm';
import BaseInput from 'components/formComponents/Inputs/BaseInput';
import Button from 'components/formComponents/Button/Button';
import { getFormattedMembers, getMembersTextList } from 'utils/memberUtil';
import { handleChange } from 'utils/formUtil';
import { getFilteredItems } from 'utils/searchUtil';
import { object } from 'prop-types';
import './EditTree.css';

const _getFilteredItems = (membersList, search) => {
  const filteredItems = getFilteredItems(membersList, search);
  return search ? filteredItems : membersList;
};

const EditTree = ({ members }) => {
  const [showMemberForm, setShowMemberForm] = useState(false);
  const [search, setSearch] = useState('');
  const [memberId, setMemberId] = useState('');
  const formatedMembers = getFormattedMembers(members);
  const membersList = getMembersTextList(formatedMembers);
  const filteredItems = _getFilteredItems(membersList, search);
  const openMemberForm = (memberId = '') => {
    setShowMemberForm(true);
    setMemberId(memberId);
  };
  const hideMemberForm = () => {
    setShowMemberForm(false);
  };
  if (showMemberForm || membersList.length === 0) {
    return <MemberForm hideMemberForm={hideMemberForm} memberId={memberId} />;
  }
  return (
    <>
      <div className="defaultPadding">
        <div className="memberListContainer">
          <Button onClick={() => openMemberForm()} className="showMemberForm">
            Add Member
          </Button>
          <div className="inputContainer">
            <BaseInput onChange={handleChange(setSearch)} value={search}>
              Search
            </BaseInput>
          </div>
          {filteredItems.map(({ value, memberId }) => {
            // TODO:  Add no results found case
            return (
              <div
                key={memberId}
                className="memberList"
                onClick={() => openMemberForm(memberId)}
              >
                {value}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

EditTree.propTypes = {
  members: object,
};

const mapStateToProps = ({ familyData }) => ({
  members: familyData.members,
});

export default connect(mapStateToProps)(EditTree);
