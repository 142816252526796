import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Header from 'containers/Header/Header';
import Main from 'containers/Main/Main';
import 'App/App.css';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Main />
      </div>
    </BrowserRouter>
  );
}

export default App;
