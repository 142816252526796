import { combineReducers } from 'redux';
import authentication from './authenticationReducer';
import familyData from './memberReducer';
import options from './optionsReducer';

export default combineReducers({
  authentication,
  familyData,
  options,
});
