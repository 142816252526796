export const CREATE_MEMBER = 'CREATE_MEMBER';
export const MEMBERS_CHANGE = 'MEMBERS_CHANGE';
export const EDIT_MEMBER = 'EDIT_MEMBER';
export const DELETE_MEMBER = 'DELETE_MEMBER';

export const createMember = updatedMember => {
  return {
    type: CREATE_MEMBER,
    updatedMember,
  };
};
export const editMember = updatedMember => {
  return {
    type: EDIT_MEMBER,
    updatedMember,
  };
};
export const deleteMember = updatedMember => {
  return {
    type: DELETE_MEMBER,
    updatedMember,
  };
};
